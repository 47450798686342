import ApplicationLayout from '@/Layouts/Application'
import NotFound from '@/components/Application/NotFound'
import React from 'react'

const NotFoundPage = () => (
  <ApplicationLayout preferBits={true}>
    <NotFound />
  </ApplicationLayout>
)

export default NotFoundPage
