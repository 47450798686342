import './NotFound.scss'

import React from 'react'
import { Slice } from 'raspberry-pi-bits'

const NotFound = () => (
  <Slice className="c-not-found">
    <div className="c-not-found__container">
      <div className="c-not-found__status-code">404</div>
      <h1 className="c-not-found__message">
        We couldn&apos;t find what you were looking for.
      </h1>
    </div>
  </Slice>
)

export default NotFound
